import mixins from "vue-typed-mixins";
import { mapState } from "vuex";
import addToOrCreateCart from "../../../api/addToOrCreateCart";
import AsyncStatus from "../../../utils/async";
import getAbsoluteUrl from "../../../router/getAbsoluteUrl";
import NotificationsMixin from "../../ui/NotificationsMixin";
export default mixins(NotificationsMixin).extend({
    props: {
        productId: {
            type: String,
            default: undefined,
        },
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    computed: mapState(["cartId"]),
    methods: {
        onClick: function () {
            var _this = this;
            if (!this.productId) {
                return;
            }
            this.status
                .run(addToOrCreateCart(this.$apollo, this.$store, this.$t.bind(this), this.productId, this.metadata).then(function () {
                // TODO: Change back to router. This is a temp fix for cart number not increasing
                // Note: probably to do with including __typename in all cart objects. I think when
                // I set this up it was my first use of apollo and didn't understand the local cache.
                document.location.href = getAbsoluteUrl(_this.$store, _this.$router, "/cart");
                // this.$router.push("/cart");
            }))
                .catch(this.displayErrorMessage);
        },
    },
});

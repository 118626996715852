var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import AddToCartButton from "../cart/AddToCartButton.vue";
import SmallBlogPostCard from "../general/SmallBlogPostCard.vue";
import FeatureList from "../../ui/FeatureList.vue";
import SmallBlogPostsSection from "../../ui/SmallBlogPostsSection.vue";
import ReviewsSection from "../../ui/ReviewsSection.vue";
import { moneyFormat } from "../../../utils/filters";
import ProductListingLoading from "../general/ProductListingLoading.vue";
export default Vue.extend({
    components: {
        ProductListingLoading: ProductListingLoading,
        AddToCartButton: AddToCartButton,
        FeatureList: FeatureList,
        SmallBlogPostsSection: SmallBlogPostsSection,
        ReviewsSection: ReviewsSection,
    },
    apollo: {
        liveBlogPostsByTag: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query ListingBlogPostsQuery($tag: String!) {\n          liveBlogPostsByTag(tag: $tag, amount: 4) {\n            id\n            ...SmallBlogPostCard_post\n          }\n        }\n        ", "\n      "], ["\n        query ListingBlogPostsQuery($tag: String!) {\n          liveBlogPostsByTag(tag: $tag, amount: 4) {\n            id\n            ...SmallBlogPostCard_post\n          }\n        }\n        ", "\n      "])), SmallBlogPostCard.options.fragments.post),
            variables: function () {
                return { tag: "m" };
            },
        },
        mobileProductBySlug: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query ListingQuery($slug: String!) {\n          mobileProductBySlug(slug: $slug) {\n            id\n            productId\n            metaTitle\n            metaDescription\n            heading\n            name\n            description\n            features {\n              description\n              icon\n            }\n            mainImageUrl\n            criticalProductInformationUrl\n            pricePerMonth {\n              currency\n              amount\n            }\n            reviews {\n              person\n              content\n            }\n          }\n        }\n      "], ["\n        query ListingQuery($slug: String!) {\n          mobileProductBySlug(slug: $slug) {\n            id\n            productId\n            metaTitle\n            metaDescription\n            heading\n            name\n            description\n            features {\n              description\n              icon\n            }\n            mainImageUrl\n            criticalProductInformationUrl\n            pricePerMonth {\n              currency\n              amount\n            }\n            reviews {\n              person\n              content\n            }\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    slug: this.$route.params.slug,
                };
            },
        },
    },
    computed: {
        heading: function () {
            if (!this.mobileProductBySlug) {
                return;
            }
            return (moneyFormat(this.mobileProductBySlug.pricePerMonth) +
                " " +
                this.mobileProductBySlug.heading);
        },
    },
    metaInfo: function () {
        if (!this.mobileProductBySlug) {
            return undefined;
        }
        return {
            title: this.mobileProductBySlug.metaTitle,
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: this.mobileProductBySlug.metaDescription,
                },
            ],
        };
    },
});
var templateObject_1, templateObject_2;

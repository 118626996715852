import Vue from "vue";
import checkCircleIconImage from "../../images/check-circle-icon.svg";
import clockIconImage from "../../images/clock.svg";
import simIconImage from "../../images/sim.svg";
import dataIconImage from "../../images/data.svg";
import internationalCallIconImage from "../../images/international-call.svg";
import unlimitedCallsIconImage from "../../images/unlimited-calls.svg";
var ICON_IMAGES = {
    check: checkCircleIconImage,
    clock: clockIconImage,
    sim: simIconImage,
    data: dataIconImage,
    "international-call": internationalCallIconImage,
    "unlimited-calls": unlimitedCallsIconImage,
};
export default Vue.extend({
    props: {
        features: {
            type: Array,
            required: true,
        },
    },
    computed: {
        featuresWithIcons: function () {
            return this.features.map(function (_a) {
                var description = _a.description, icon = _a.icon;
                return ({
                    description: description,
                    icon: ICON_IMAGES[icon] || ICON_IMAGES["check"],
                });
            });
        },
    },
});

import Vue from "vue";
var MULTIPLE_SWIPER_OPTIONS = {
    autoHeight: true,
    grabCursor: true,
    allowTouchMove: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
};
var SINGLE_SWIPER_OPTIONS = {
    autoHeight: true,
    grabCursor: false,
    allowTouchMove: false,
};
export default Vue.extend({
    props: {
        reviews: { type: Array, required: true },
    },
    data: function () {
        return {
            swiperOptions: this.reviews.length > 1
                ? MULTIPLE_SWIPER_OPTIONS
                : SINGLE_SWIPER_OPTIONS,
        };
    },
});
